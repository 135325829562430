<template>
  <v-select
    v-model="ticketTemplate"
    :items="ticketTemplates"
    item-text="name"
    item-value="id"
    :label="$t('ticket-template')"
  >
    <template slot="item" slot-scope="data">
      <div class="body-2">{{ data.item.name }}</div>
    </template>
  </v-select>
</template>

<script>
export default {
  name: "TicketCheckinParams",
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    groupId: {
      type: Number,
      required: true,
    },
    groupPluginId: {
      type: [Number, String, null],
    },
  },
  data: () => ({
    ticketTemplates: [],
    ticketTemplate: null,
  }),
  computed: {
    appContentLanguage() {
      return this.$store.getters.appContentLanguage;
    },
  },
  watch: {
    groupPluginId: {
      handler() {
        this.fetchAll();
      },
    },
    appContentLanguage: {
      handler() {
        this.fetchAll();
      },
    },
  },
  mounted() {
    this.fetchAll();
  },
  methods: {
    async fetchAll() {
      try {
        await this.listGroupTicketTemplates();
        await this.getGroupTicketTemplate();
      } catch (error) {
        if (error) {
          this.errorMessageShow(error);
          return;
        }
      }
    },
    async listGroupTicketTemplates() {
      const params = [this.groupId, { group_plugin_id: this.groupPluginId }];

      const response = await this.$api.groupTicketTemplates.list(...params);

      this.ticketTemplates = response.data.data;
    },
    async getGroupTicketTemplate() {
      if (!this.value || !this.value.ticket_template_id) {
        return;
      }

      const params = [
        this.groupId,
        this.value.ticket_template_id,
        { lang: this.appContentLanguage },
      ];

      const response = await this.$api.groupTicketTemplates.get(...params);

      this.ticketTemplate = response.data.data;
    },
    updateValue() {
      let params = null;

      if (this.ticketTemplate && this.ticketTemplate.id) {
        params = { ticket_template_id: this.ticketTemplate.id };
      }

      this.$emit("input", params);
    },
  },
};
</script>
