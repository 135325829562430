<template>
  <v-combobox
    v-model="groupArticle"
    :items="groupArticles"
    :search-input.sync="search"
    :menu-props="{ closeOnContentClick: true }"
    :item-text="'name'"
    :item-value="'id'"
    return-object
    :placeholder="placeholder"
    chips
    no-filter
    hide-details
    hide-selected
    deletable-chips
    @input="updateValue()"
  >
    <template slot="item" slot-scope="data">
      <div class="body-2">{{ data.item.name }}</div>
    </template>
  </v-combobox>
</template>

<script>
export default {
  name: "ArticleParams",
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    groupId: {
      type: [Number, String],
      required: true,
    },
    groupPluginId: {
      type: [Number, String],
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    groupArticles: [],
    groupArticle: null,
    search: null,
    timeout: null,
    perPage: 10,
  }),
  computed: {
    appContentLanguage() {
      return this.$store.getters.appContentLanguage;
    },
  },
  watch: {
    groupPluginId: {
      handler() {
        this.fetchAll();
      },
    },
    appContentLanguage: {
      handler() {
        this.fetchAll();
      },
    },
    search() {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => this.listGroupArticles(), 800);
    },
  },
  mounted() {
    this.fetchAll();
  },
  methods: {
    async fetchAll() {
      try {
        await this.listGroupArticles();
        await this.getGroupArticle();
      } catch (error) {
        if (error) {
          this.errorMessageShow(error);
          return;
        }
      }
    },
    async listGroupArticles() {
      const params = [
        this.groupId,
        {
          group_plugin_id: this.groupPluginId,
          search: this.search,
          lang: this.appContentLanguage,
          per_page: this.perPage,
        },
      ];

      const response = await this.$api.groupArticles.list(...params);

      this.groupArticles = response.data.data;
    },
    async getGroupArticle() {
      if (!this.value || !this.value.article_id) {
        return;
      }

      const params = [
        this.groupId,
        this.value.article_id,
        { lang: this.appContentLanguage },
      ];

      const response = await this.$api.groupArticles.get(...params);

      this.groupArticle = response.data.data;
    },
    updateValue() {
      let params = null;

      if (this.groupArticle && this.groupArticle.id) {
        params = { article_id: this.groupArticle.id };
      }

      this.$emit("input", params);
    },
  },
};
</script>

<style scoped></style>
