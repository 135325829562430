import { render, staticRenderFns } from "./ArticleParams.vue?vue&type=template&id=5a460a76&scoped=true&"
import script from "./ArticleParams.vue?vue&type=script&lang=js&"
export * from "./ArticleParams.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a460a76",
  null
  
)

export default component.exports