<template>
  <v-container class="general">
    <page-title :title="$t('group_menu_destination.page_title')">
      <template slot="actions">
        <LanguageSwitcher
          ref="languageSwitcher"
          :events="true"
          @click="saveGroupMenuDestination('stay')"
          :loading="isLoading"
        />
      </template>
    </page-title>
    <v-layout row wrap>
      <v-flex xs12 class="mb-4">
        <div class="sw-h5">
          {{ $t("group_menu_destination.name_label") }}
        </div>
        <v-text-field
          v-model="destination.name"
          v-validate.disable="'required'"
          :error-messages="errors.collect('name')"
          :data-vv-name="'name'"
          :data-vv-as="$t('group_menu_destination.name_data_vv_as')"
          :placeholder="$t('group_menu_destination.name_placeholder')"
        ></v-text-field>
      </v-flex>
      <v-flex xs12 class="mb-4">
        <div class="mb-4 sw-h5">
          {{ $t("group_menu_destination.menu_icon_label") }}
        </div>
        <IconSelector
          v-model="destination.icon"
          :label="$t('group_menu_destination.menu_icon_hint')"
          class="mb-3"
        />
      </v-flex>
      <v-flex
        v-if="filteredComponents && filteredComponents.length"
        xs12
        class="mb-4"
      >
        <div class="sw-h5">
          {{ $t("group_menu_destination.component_label") }}
        </div>
        <v-autocomplete
          v-model="destination.component"
          :items="filteredComponents"
          item-text="name"
          item-value="entity"
          v-validate.disable="'required'"
          :data-vv-name="'component'"
          :data-vv-as="$t('group_menu_destination.component_data_vv_as')"
          :error-messages="errors.collect('component')"
          return-object
          :placeholder="$t('group_menu_destination.component_placeholder')"
          persistent-hint
          @input="clearDestinationParams"
        >
          <template slot="item" slot-scope="data">
            <v-layout row wrap>
              <v-flex xs6 class="sw-body-2 slot-item">{{
                data.item.name
              }}</v-flex>
              <v-flex xs6 text-xs-right class="caption">
                {{ data.item.description }}
              </v-flex>
            </v-layout>
          </template>
        </v-autocomplete>
      </v-flex>
      <template v-if="destination.component">
        <v-flex v-if="destination.component.plugin" xs12 class="mb-4">
          <!-- @Group plugin selector -->
          <div class="sw-h5">
            {{ $t("group_menu_destination.plugin_label") }}
          </div>
          <v-select
            v-model="destination.group_plugin_id"
            :items="groupPlugins"
            item-text="name"
            item-value="id"
            v-validate.disable="'required'"
            :data-vv-name="'plugin'"
            :data-vv-as="$t('group_menu_destination.plugin_data_vv_as')"
            :error-messages="errors.collect('plugin')"
            :placeholder="$t('group_menu_destination.plugin_placeholder')"
          >
            <template slot="item" slot-scope="data">
              <v-layout row wrap>
                <v-flex xs6 class="sw-body-2 slot-item">{{
                  data.item.name
                }}</v-flex>
                <v-flex xs6 text-xs-right class="caption">
                  {{ data.item.prefix }}
                </v-flex>
              </v-layout>
            </template>
          </v-select>
        </v-flex>
        <template v-if="menu.slug === 'group_menu'">
          <v-flex
            v-if="
              (destination.component.entity === 'TicketsCheckin' ||
                destination.component.entity === 'TicketStats') &&
              destination.group_plugin_id
            "
            xs12
            class="mb-4"
          >
            <div class="sw-h5">
              {{ $t("group_menu_destination.params_label") }}
            </div>
            <template v-if="destination.component.entity === 'TicketsCheckin'">
              <TicketCheckInParams
                v-model="destination.params"
                :groupId="groupId"
                :groupPluginId="destination.group_plugin_id"
              />
            </template>
            <template v-if="destination.component.entity === 'TicketStats'">
              <TicketStatsParams
                v-model="destination.params"
                :groupId="groupId"
                :groupPluginId="destination.group_plugin_id"
              />
            </template>
          </v-flex>
        </template>
        <template v-if="menu.slug === 'ionic-app-sidemenu'">
          <v-flex
            v-if="
              destination.component.entity === 'ArticlePage' &&
              destination.group_plugin_id
            "
            xs12
            class="mb-4"
          >
            <div class="sw-h5">
              {{ $t("group_menu_destination.article_params_label") }}
            </div>
            <ArticleParams
              v-model="destination.params"
              :groupId="groupId"
              :groupPluginId="destination.group_plugin_id"
              :placeholder="
                $t('group_menu_destination.article_params_placeholder')
              "
            />
          </v-flex>
        </template>
      </template>
      <v-flex xs12 class="mb-4">
        <v-switch v-model="destination.enabled">
          <template slot="label">
            <div class="black--text">
              {{ $t("group_menu_destination.enabled_label") }}
            </div>
          </template>
        </v-switch>
      </v-flex>
    </v-layout>
    <div>
      <v-btn
        round
        large
        class="ml-0 mr-3 white sw-accent text-none"
        @click="
          $router.push({
            name: 'group_menu_destination_list',
            params: { group_id: groupId, menu_id: menu.id },
          })
        "
        >{{ $t("common.cancel") }}</v-btn
      >
      <v-btn
        round
        large
        class="ml-0 mr-3 sw-accent-bg sw-on-accent text-none"
        @click="saveGroupMenuDestination()"
        :loading="isLoading"
        >{{ !destinationId ? $t("common.create") : $t("common.save") }}</v-btn
      >
    </div>
    <ConfirmationDialog ref="confirmationDialog" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { components } from "@/supported-components.json";
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
import TicketCheckInParams from "@/components/GroupMenuDestination/AdminMenu/TicketCheckInParams.vue";
import TicketStatsParams from "@/components/GroupMenuDestination/AdminMenu/TicketStatsParams.vue";
import ArticleParams from "@/components/GroupMenuDestination/AppMenu/ArticleParams.vue";

export default {
  data: () => ({
    isLoading: false,
    menu: {},
    destination: {
      icon: "fas question",
      component: null,
      group_plugin_id: null,
      params: null,
      enabled: false,
    },
    oldComponentEntity: "",
    groupPlugins: [],
    unavailableComponentsForEdit: ["TicketPage"],
  }),
  computed: {
    ...mapGetters(["appLanguage", "appContentLanguage"]),
    groupId() {
      const val = this.$route.params.group_id;
      if (val && typeof val !== "number") {
        return Number(val);
      }
      return val;
    },
    menuId() {
      return this.$route.params.menu_id;
    },
    destinationId() {
      return this.$route.params.destination_id;
    },
    components() {
      return components;
    },
    filteredComponents() {
      if (!this.menu || !this.menu.destinations || !this.components) {
        return [];
      }

      return this.components.filter((component) => {
        if (
          component.menu_slug !== this.menu.slug ||
          component.read_only ||
          (!component.multiple &&
            this.menu.destinations.some(
              (dest) => dest.component === component.entity,
            ) &&
            this.oldComponentEntity !== component.entity) ||
          this.unavailableComponentsForEdit.includes(component.entity)
        ) {
          return false;
        }
        return true;
      });
    },
  },
  components: {
    LanguageSwitcher,
    TicketCheckInParams,
    TicketStatsParams,
    ArticleParams,
  },
  watch: {
    appContentLanguage: {
      handler(newVal, oldVal) {
        if (newVal === oldVal) return;
        this.fetchAll();
      },
    },
  },
  mounted() {
    this.fetchAll();
  },
  methods: {
    clearDestinationParams() {
      this.destination.group_plugin_id = null;
      this.destination.params = null;
    },
    async fetchAll() {
      try {
        this.isLoading = true;

        await this.getGroupMenu();
        await this.listGroupPlugins();

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
          return;
        }
      }
    },
    transformDestinationsForSave(model) {
      return {
        name: model.name,
        component: model.component.entity,
        group_plugin_id: model.group_plugin_id,
        params: model.params ? JSON.stringify(model.params) : null,
        icon: model.icon,
        enabled: model.enabled ? 1 : 0,
      };
    },
    transformDestinationsForEdit(model) {
      return {
        id: model.id,
        name: model.name,
        component: this.components.find((el) => el.entity === model.component),
        group_plugin_id: model.group_plugin_id ? model.group_plugin_id : null,
        params: JSON.parse(model.params),
        icon: model.icon,
        enabled: model.enabled,
      };
    },
    reloadLeftMenu() {
      if (this.menu.slug !== "group_menu") return;

      const params = [
        this.groupId,
        {
          lang: this.appLanguage,
        },
      ];

      this.$store.dispatch("getGroupLeftMenu", params);
    },
    async listGroupPlugins() {
      this.groupPlugins = [];

      const response = await this.$api.groupPlugins.list(this.groupId, {
        lang: this.appContentLanguage,
      });

      if (!response || !response.data.data[0]) return;

      this.groupPlugins = response.data.data;
    },
    async createGroupMenuDestination(action) {
      const params = [
        this.groupId,
        this.menu.id,
        {
          ...this.transformDestinationsForSave(this.destination),
          lang: this.appContentLanguage,
        },
      ];

      const response = await this.$api.groupMenuDestinations.create(...params);

      this.$store.dispatch("addNotification", {
        message: this.$t("group_menu_destination.create_success_message"),
      });

      this.reloadLeftMenu();

      if (action !== "stay") {
        this.$router
          .push({
            name: "group_menu_destination_list",
            params: {
              group_id: this.groupId,
              menu_id: this.menu.id,
            },
          })
          .catch(() => {});

        return;
      }

      this.$router
        .push({
          name: "group_menu_destination_edit",
          params: {
            group_id: this.groupId,
            menu_id: this.menu.id,
            destination_id: response.data.data.id,
          },
        })
        .catch(() => {});
    },
    async getGroupMenu() {
      if (!this.groupId || !this.menuId) return;

      const params = [
        this.groupId,
        this.menuId,
        {
          lang: this.appContentLanguage,
          with_disabled: 1,
        },
      ];

      const response = await this.$api.groupMenus.get(...params);

      if (!response || !response.data.data.id) return;

      this.menu = response.data.data;

      if (!this.destinationId) return;

      const destination = response.data.data.destinations.find(
        (el) => Number(el.id) === Number(this.destinationId),
      );

      this.destination = this.transformDestinationsForEdit(destination);
      this.oldComponentEntity = destination.component;
    },
    async saveGroupMenuDestination(action) {
      const isValid = await this.$validator.validate();

      if (!isValid) return;

      try {
        this.isLoading = true;

        if (!this.destination.id) {
          await this.createGroupMenuDestination(action);
        } else {
          await this.updateGroupMenuDestination(action);
        }

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
          return;
        }
      }
    },
    async updateGroupMenuDestination(action) {
      const params = [
        this.groupId,
        this.menu.id,
        this.destination.id,
        {
          ...this.transformDestinationsForSave(this.destination),
          lang: this.appContentLanguage,
        },
      ];

      await this.$api.groupMenuDestinations.update(...params);

      this.reloadLeftMenu();

      this.$store.dispatch("addNotification", {
        message: this.$t("group_menu_destination.update_success_message"),
      });

      if (action !== "stay") {
        this.$router
          .push({
            name: "group_menu_destination_list",
            params: {
              group_id: this.groupId,
              menu_id: this.menu.id,
            },
          })
          .catch(() => {});
      } else {
        this.$refs.languageSwitcher.open();
      }
    },
  },
};
</script>

<style scoped>
.loading {
  opacity: 0.4;
  transition: all 0.2s;
  pointer-events: none;
}
</style>
